<template>
  <Html :lang="htmlAttrs.lang" :dir="htmlAttrs.dir">

  <Body>
    <div>
      <v-app>
        <div id="body-content"></div>
        <Header />
        <div class="navSpace"></div>
        <div class="hideInMobile">
          <div class="catagory-dropdown " v-if="productsMenu.length || brandImages.length">
          <div class="products-dropdown " v-if="productsMenu.length">
            <div class="cat-tree ">
              <CategoryCategoryTree />
            </div>

            <div class="catagory-items ">
              <div class="all-items all-items-deals green-nav-height">

                <template v-if="productsMenu">
                  <div class="catagory-items-sub-info catagory-item-sub" v-for="pm in productsMenu" :key="pm.key">
                    <p v-if="!privateProductsMenus[pm.key] || (privateProductsMenus[pm.key] && isAuthenticated)"
                      class="catagory-text" @click="addFilter(pm.query)">
                      <img :src="image(pm.img, { height: 25 })"  style="width: 25px;" alt="icon"/>
                      {{ $t(`menu.${pm.key}`) }} {{ "&nbsp; " }}
                    </p>
                  </div>
                </template>

              </div>
            </div>

          </div>
  
          <div class="catagory-brands" v-if="brandImages.length">
            <swiper :watchSlidesProgress="true" :slidesPerView="12" :navigation="false" :grabCursor="true" :autoplay="{
              delay: 1500,
              disableOnInteraction: false,
            }" :freeMode="true" :centeredSlides="false" :modules="modules" class="mySwiper swiper">
              <swiper-slide v-for="bImg in brandImages" :key="bImg.slug" class="swiper-slide">
                <NuxtLinkLocale :to="bImg.url" class="catagory-brands-sub-info" aria-label="Image-Link">
                  <img :src="imgUrl(bImg.image.url)" class="img-info-brands" alt="Image" />
                </NuxtLinkLocale>
              </swiper-slide>
            </swiper>
          </div>

        </div>
        <!-- <div  class="placeholder bg-light catagory-dropdown-placeholder">
        </div> -->
        <div v-else class="catagory-dropdown-placeholder shadeLoad loading">
          <div class="strappro"></div>
        </div>
</div>

      
        <WidgetsBreadcrumbs />
        <div class="container">
          <MiniSearch :range="15"></MiniSearch>
        </div>

        <NuxtPage />
        <ClientOnly>
          <div class="tap-top top-cls" v-scroll-to="'#body-content'">
            <div>
              <i class="fa fa-angle-double-up"></i>
            </div>
          </div>
        </ClientOnly>

        <LazyFooter> </LazyFooter>
      </v-app>
    </div>
  </Body>

  </Html>
</template>

<script setup>
// import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import {
  Navigation,
  Pagination,
  History,
  FreeMode,
  Autoplay,
} from "swiper/modules";
const modules = [Navigation, Pagination, History, FreeMode, Autoplay];
import CategoryCategoryTree from '../components/Category/TreeNavigation.vue';
import { useCategoryStore } from "~/store/CategoryStore";
import { useUtilityStore } from "~/store/UtililityStore";
import { useCmsStore } from "~/store/cms/cmsStore";
import { useI18n } from "vue-i18n";
import { useUserStore } from "~/store/UserStore";
import { productsMenu, privateProductsMenus } from "~/composables/data";
import { useFilterStore } from "~/store/FilterStore";
import { useOrderStore } from "~/store/OrderStore";

const { t, locale } = useI18n();

const head = useLocaleHead({
  addDirAttribute: true,      // Adds dir
  addSeoAttributes: true,     // Adds lang
})
useHead({
  title: "CLD Distribution - Leading European Distributor and Wholesaler in Entertainment and Pop-Culture ",
  meta: [{ name: "description", content: t('cld_desc1') }],
  link: [...(head.value.link || [])],
});

const htmlAttrs = computed(() => head.value.htmlAttrs)
const image = useImage()
const cmsStore = useCmsStore();
const filterStore = useFilterStore();
const categoryStore = useCategoryStore();
const utilityStore = useUtilityStore();
const userStore = useUserStore();
const orderStore = useOrderStore();
const selectedLanguage = computed(() => utilityStore.selectedLanguage);
const categoryImages = computed(() => cmsStore.categoryImages);
const brandImages = computed(() => cmsStore.brandImages);
const parentCategories = computed(() => categoryStore.parentCategories);
const localePath = useLocalePath();
const parentCategoriesObj = computed(() => {
  return {
    ...Object.assign(
      {},
      ...parentCategories.value.map((x) => {
        return {
          [x.key]: { ...x },
        };
      })
    ),
  };
});
const isAuthenticated = computed(() => userStore.isAuthenticated);

// const categoryImagesWithLabels = computed(() => {
//   const result = [];
//   for (let i = 0; i < categoryImages.value.length; i++) {
//     const slug = categoryImages.value[i].slug;
//     if (parentCategoriesObj.value[slug]) {
//       const label =
//         parentCategoriesObj.value[slug].parent_top_hits.hits.hits[0]._source
//           .Values.category_tree[0].Data;
//       result.push({
//         label,
//         ...categoryImages.value[i],
//       });
//     }
//   }

//   return result;
// });
const router = useRouter();
const imgUrl = (url) => cmsStore.getImageUrl(url);
const addFilter = (v) => {
  router.push({ path: localePath('/products/search') })
  setTimeout(() => {
    const { attribute, sort } = v
    filterStore.setSelectedAttributeFilters(attribute)
    if (sort && sort?.length) {
      filterStore.setSelectedSort(sort)
    }
  }, 500);
}
onBeforeMount(() => {
  setTimeout(() => {
    categoryStore.getParentCategories();
    cmsStore.getHomePage();
    cmsStore.getLicense();
    cmsStore.getCoverSliders();
    cmsStore.getCmsCategory();
    cmsStore.getCmsBrand();
    cmsStore.getCmsSlider();

  }, 0);
});

useAsyncData('init_cms_cache', async () => {
  return cmsStore.initFromCache();
}, {
  lazy: true,
  server: false,
});

onMounted(() => {
  setTimeout(() => {
    orderStore.getAccountingSituationLocalStorage()
  }, 2000);
})

useAsyncData('deliverable-orders', async () => {
  return orderStore.getDeliverableOrdersTotal('default layout');
}, {
  lazy: true,
  server: false,

})
</script>
<style>
/* CLS */

.catagory-dropdown-placeholder {
  height: 208.6px !important;
  width: 100%;
}
/* .hideInMobile{
  display: block;
} */
</style>
